import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridItem, Centered } from '@sdflc/ui';
import { ContactUsBlock, Logo } from '../index';
import { Container } from './styled';

const Footer = memo((props) => {
  const { locationDetails, isLoading } = props;

  return (
    <Container>
      <Grid>
        <GridItem width={['100%', '100%', '100%', '100%']}>
          <Centered>
            <Logo square={false} size='small' />
          </Centered>
        </GridItem>
        {/* <GridItem width={['100%', '100%', '60%', '60%']}></GridItem>
        <GridItem width={['100%', '100%', '30%', '30%']}>
          <ContactUsBlock
            locationDetails={locationDetails}
            isLoading={isLoading}
            showTitle
          />
        </GridItem> */}
      </Grid>
    </Container>
  );
});

Footer.displayName = 'Footer';

Footer.propTypes = {
  locationDetails: PropTypes.any,
  isLoading: PropTypes.bool,
};

Footer.defaultProps = {
  locationDetails: null,
  isLoading: false,
};

export { Footer };
