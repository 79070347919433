import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { AppTheme } from '@sdflc/ui';

import { localTheme, siteInfo } from '../../config';

import { PageContent } from './styled';
//import { Footer } from '../../index';

const HomeLayout = memo((props) => {
  const { children, title, description } = props;

  return (
    <AppTheme theme={localTheme}>
      <Helmet>
        <title>{title}</title>
        <meta
          name='description'
          content={description || siteInfo.description}
        />
        <style>{`
          html, body { font-family: 'Montserrat', sans-serif; }
        `}</style>
      </Helmet>
      <PageContent>{children}</PageContent>
      {/* <Footer /> */}
    </AppTheme>
  );
});

HomeLayout.displayName = 'HomeLayout';

HomeLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
};

export { HomeLayout };
