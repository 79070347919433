import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Image } from '@sdflc/ui';

import { siteInfo } from '../../config';

import { Link } from '../Link';

import { Container } from './styled';

const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  X_LARGE: 'x-large',
};

const size2px = {
  [SIZES.SMALL]: '40px',
  [SIZES.MEDIUM]: '100px',
  [SIZES.LARGE]: '200px',
  [SIZES.X_LARGE]: '400px',
};

const Logo = memo((props) => {
  const { square, darkBg, size } = props;
  const height = size2px[size];
  const shape = square ? 'square' : 'rectangular';
  const bgColor = darkBg ? 'dark' : 'light';

  return (
    <Container>
      <Link href='/'>
        <Image
          src={`${siteInfo.assetsBaserUrl}/logo/${shape}-${bgColor}.png`}
          style={{ height: height }}
        />
      </Link>
    </Container>
  );
});

Logo.displayName = 'Logo';
Logo.sizes = SIZES;

Logo.propTypes = {
  square: PropTypes.bool,
  darkBg: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
};

Logo.defaultProps = {
  square: true,
  darkBg: false,
  size: SIZES.SMALL,
};

export { Logo };
