import styled from 'styled-components';
import { colors } from '../../config';

const Container = styled.div`
  position: ${({ header }) => (header ? 'absolute' : 'relative')};
  top: ${({ header }) => (header ? '30px' : 'unset')};
  right: ${({ header }) => (header ? '30px' : 'unset')};
  text-shadow: ${({ header }) => (header ? '0 0 5px #333' : 'unset')};
  z-index: 1;
  color: ${colors.white};
  width: 250px;
  max-width: 80%;
  word-wrap: normal;
`;

export { Container };
