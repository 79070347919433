import styled from 'styled-components';

export const PageContent = styled.main`
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  @media print {
    font-size: 8pt;
    h3 {
      font-size: 1.2em;
    }
  }
`;
