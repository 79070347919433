import { defaultTheme } from '@sdflc/ui';
import { colors } from './colors';

export const localTheme = Object.assign({}, defaultTheme, {
  GlobalStyles: {
    fontSize: 3,
    lineHeight: 'normal',
    fontFamily: 0,
    margin: 0,
    padding: 0,
    minHeight: '100vh',
    backgroundColor: colors.white,
    color: colors.black,
  },
  EmptyLayout: {
    fontSize: 3,
    lineHeight: 'normal',
    fontFamily: 0,
  },
  Header: {
    container: {
      backgroundColor: colors.greenishWhite,
      padding: 1,
    },
  },
  Main: {
    container: {
      backgroundColor: colors.transparent,
    },
  },
  Caption: {
    h1: {
      fontFamily: "'Mitr', sans-serif;",
      fontSize: ['28px', '32px', '54px'],
      lineHeight: 'normal',
      textTransform: 'uppercase',
      color: colors.black,
      margin: 0,
      marginBottom: 30,
    },
    h2: {
      fontFamily: "'Mitr', sans-serif;",
      fontSize: ['28px', '32px', '54px'],
      lineHeight: 'normal',
      textTransform: 'uppercase',
      color: colors.black,
      fontWeight: '600',
    },
    h3: {
      fontFamily: "'Mitr', sans-serif;",
      fontSize: ['22px', '26px', '32px'],
      lineHeight: '44px',
      textTransform: 'uppercase',
      textAlign: 'left',
      fontWeight: '600',
      margin: '0',
    },
    h4: {
      fontFamily: "'Mitr', sans-serif;",
      fontSize: '16px',
      lineHeight: 'normal',
      textTransform: 'uppercase',
      margin: '0',
      marginBottom: '3',
    },
    h5: {
      fontFamily: "'Mitr', sans-serif;",
      fontSize: '16px',
      lineHeight: 'normal',
      color: colors.black,
      margin: '0',
    },
    h6: {
      fontFamily: "'Mitr', sans-serif;",
      fontSize: '16px',
      lineHeight: 'normal',
      margin: '0',
    },
  },
  InputContainer: {
    ...defaultTheme.InputContainer,
    common: {
      ...defaultTheme.InputContainer.common,
      backgroundColor: colors.white,
      overflow: 'hidden',
      borderRadius: '6px',
      minHeight: '50px',
    },
    rightSide: {
      ...defaultTheme.InputContainer.rightSide,
      padding: '0',
    },
  },
  Input: {
    ...defaultTheme.Input,
    input: {
      ...defaultTheme.Input.input,
      common: {
        ...defaultTheme.Input.input.common,
        fontSize: '20px',
      },
    },
  },
  Button: {
    ...defaultTheme.Button,
    variants: {
      ...defaultTheme.Button.variants,
      primary: {
        ...defaultTheme.Button.variants.primary,
        backgroundColor: colors.white,
        color: colors.red,
      },
    },
  },
});
