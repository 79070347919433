export const siteInfo = {
  siteName: 'Grass Equal',
  title: 'Grass Equal',
  description: '',
  assetsBaserUrl: 'https://storage.googleapis.com/grass-equal-assets',
  canonical: 'http://grassequal.com',
  image:
    'https://storage.googleapis.com/grass-equal-assets/grassequal-social.png',
  twitterHandle: '',
  author: 'Grass Equal',
  classification: 'shopping',
};
