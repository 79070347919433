import { colors as uiColors } from '@sdflc/ui';

export const colors = {
  ...uiColors,
  white: '#FFF',
  black: '#003C02',
  red: '#E43F28',
  organge: '#E57C00',

  gray: 'rgba(0, 0, 0, 0.5)',
  lightGray: '#E5E5E5',
  lightGray2: '#DFDFDF',
  brightGreen: '#13E800',

  transparent: 'transparent',
  greenishWhite: '#f9fff9',
  darkGreen: '#002d00',
};
