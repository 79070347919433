import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styled';
import { Icon, ItemsGroup, VisibleAbove, ProgressIndicator, Centered, Caption, Panel } from '@sdflc/ui';
import { colors } from '../../config';

const ContactUsBlock = memo((props) => {
  const { header, showTitle, locationDetails, isLoading } = props;
  const { phone, email, address } = locationDetails || {};

  if (!locationDetails) {
    return null;
  }

  return (
    <VisibleAbove breakpoint={header ? 'md' : 'xs'}>
      <Container header={header}>
        {showTitle ? (
          <ItemsGroup wrapMode='nowrap'>
            <Panel width={'20px'} height={'19px'} />
            <Caption level={4}>Contact Us</Caption>
          </ItemsGroup>
        ) : null}
        {isLoading ? (
          <Centered>
            <ProgressIndicator color={colors.white} size={32} />
          </Centered>
        ) : null}
        {!isLoading && phone ? (
          <ItemsGroup wrapMode='nowrap'>
            <Icon name='LocalPhone' />
            <div>{phone}</div>
          </ItemsGroup>
        ) : null}
        {!isLoading && email ? (
          <ItemsGroup wrapMode='nowrap'>
            <Icon name='AlternateEmail' />
            <div>{email}</div>
          </ItemsGroup>
        ) : null}
        {!isLoading && address ? (
          <ItemsGroup wrapMode='nowrap'>
            <Icon name='LocationOn' />
            <div>{address}</div>
          </ItemsGroup>
        ) : null}
      </Container>
    </VisibleAbove>
  );
});

ContactUsBlock.displayName = 'ContactUsBlock';

ContactUsBlock.propTypes = {
  header: PropTypes.bool,
  locationDetails: PropTypes.any,
  isLoading: PropTypes.bool,
  showTitle: PropTypes.bool,
};

ContactUsBlock.defaultProps = {
  header: false,
  isLoading: false,
  showTitle: false,
};

export { ContactUsBlock };
