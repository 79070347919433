import { camelCase } from 'lodash';

/**
 * Creates an application settings consisting of environment variables starting with REACT_APP.
 */
const env = {
  GATSBY_SUBSCRIBE_FORM_ID: process.env.GATSBY_SUBSCRIBE_FORM_ID,
  GATSBY_GOOGLE_ANALYTICS_ID: process.env.GATSBY_GOOGLE_ANALYTICS_ID,
};

const makeVarName = (varName) => camelCase(varName.replace(/GATSBY_/i, ''));

const envVars = Object.keys(env)
  .filter((key) => key.startsWith('GATSBY_'))
  .reduce((acc, key) => {
    acc[makeVarName(key)] = env[key];
    return acc;
  }, {});

const appSettings = {
  ...envVars,
  isProduction: env.NODE_ENV === 'production',
  pageSize: 50,
};

export { appSettings };
