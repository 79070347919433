import styled from 'styled-components';
import { colors } from '../../config';

const Container = styled.footer`
  background-color: ${colors.greenishWhite};
  color: ${colors.white};
  padding: 30px;
  box-shadow: 0 -1px 15px #eee;
`;

export { Container };
