import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { AppTheme, Grid, GridItem, Section } from '@sdflc/ui';

import { PageContent } from './styled';

import { siteInfo, localTheme } from '../../config';
import { AppHeader, Footer } from '../../components';

const DefaultLayout = memo((props) => {
  const { children, title, description } = props;
  const title2use = title ? `${title} - ${siteInfo.title}` : siteInfo.title;

  return (
    <AppTheme theme={localTheme}>
      <Helmet>
        <title>{title2use}</title>
        <meta
          name='description'
          content={description || siteInfo.description}
        />
        <style>{`
          html, body { font-family: 'Montserrat', sans-serif; margin: 0; padding: 0 }
        `}</style>
      </Helmet>
      <AppHeader />
      <PageContent>
        <Section dimension='lg'>{children}</Section>
      </PageContent>
      <Footer />
    </AppTheme>
  );
});

DefaultLayout.displayName = 'DefaultLayout';

DefaultLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
};

DefaultLayout.defaultProps = {};

export { DefaultLayout };
