import styled from 'styled-components';

const Link = styled.a`
  color: currentColor;
  text-decoration: none;

  &:active,
  &:hover,
  &:focus {
    color: currentColor;
  }
`;

Link.displayName = 'Link';

export { Link };
