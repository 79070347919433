import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { ItemsGroup, Caption } from '@sdflc/ui';

const Header = memo((props) => {
  return (
    <ItemsGroup justifyContent='space-between'>
      <Caption level={3} noMargin>
        {props.text}
      </Caption>

      {props.children}
    </ItemsGroup>
  );
});

Header.displayName = 'Header';

Header.propTypes = {
  text: PropTypes.string,
};

Header.defaultProps = {};

export { Header };
