import React, { memo } from 'react';
//import PropTypes from 'prop-types';
import { ProgressIndicator } from '@sdflc/ui';

import { colors } from '../../config';

import { Container } from './styled';

const PageLoading = memo((props) => {
  return (
    <Container>
      <ProgressIndicator color={colors.red} size={64} />
    </Container>
  );
});

PageLoading.displayName = 'PageLoading';

PageLoading.propTypes = {};

PageLoading.defaultProps = {};

export { PageLoading };
